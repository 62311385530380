<template>
    <v-row class="ma-0 w-full" id="quote-generate-page">
        <details-card :totalModel="totalModel" :productPrice="productPrice"/>
        <quote-details @totalUpdate="totalModel=$event" @prodPriceUpdate="productPrice=$event"/>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';


export default {
  name: "ScanQuote",
  metaInfo: {
    title: "Quote Generation",
  },
  components: {
    detailsCard: () => import("./components/DetailCard"),
    quoteDetails: () => import("./components/QuoteDetails"),
  },
  data: (vm) => ({
    quoteScanId: vm.$route.params.quoteScanId,
    totalModel: null,
    productPrice: null
  }),
  created() {
      this.getPanelGroupPositions();
  },
  methods: {
    ...mapActions(['getPanelGroupPositions'])
  },
  beforeDestroy() {
    this.$store.commit('setQuoteDetails', null);
  }
};
</script>
